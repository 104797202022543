<template>
  <live-map :tags="Tags" />
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import LiveMap from "../leaflet/LiveMap.vue";
import { tagsWithAttributeDict } from "../../_helpers/tagHelper";
import CsharpEnum from "../../_helpers/CsharpEnum";
import { normalize } from "../../_helpers/tagHelper";

export default {
  name: "TagMap",

  data() {
    return {
      tags: [],
    };
  },

  components: {
    LiveMap,
  },

  computed: {
    ...mapState("users", ["currentUser", "userCompany", "hasCompany"]),
    ...mapGetters("event", ["lastEvent"]),

    Tags() {
      return tagsWithAttributeDict(this.tags);
    },
  },

  methods: {
    ...mapActions("tag", { getTags: "getTagsWithInformation" }),
  },

  async created() {
    this.tags = await this.getTags();
  },

  watch: {
    lastEvent(evnt) {
      if (evnt == null) return;

      let tag = this.tags.find((x) => x.deveui == evnt.deveui);
      if (!tag) return;

      if (evnt.date) tag.lastContact = evnt.date;
      if (evnt.type == CsharpEnum.EventHubType.ATTRIBUTE) {
        let normalizedAttributeNames = Object.keys(evnt.data.data).map((x) =>
          normalize(x)
        );
        tag.attributes
          .filter((x) => normalizedAttributeNames.includes(x.normalizedName))
          .forEach((x) => {
            x.value =
              evnt.data.data[x.name] ?? evnt.data.data[x.normalizedName];
          });
      }
    },
  },
};
</script>
